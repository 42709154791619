<template>
	<div class="grid dashboard">
		<div class="col-12">
			<div class="card">
				<div class="grid">
					<div class="col-3">
						<div class="field p-fluid">
							<label for="servisTalepTarihi">Servis Talep Tarihi</label>
							<Calendar showButtonBar id="servisTalepTarihi" v-model="dates" selectionMode="range" :manualInput="false" :showIcon="true" />
							{{ dates }}
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 lg:col-6">
			<div class="card height-100">
				<div class="card-header">
					<h5>Siparişler</h5>
				</div>
				<vuecharts :option="chartOption" style="height: 500px" ref="chart" />
			</div>
		</div>
		<div class="col-12 lg:col-6">
			<div class="card height-100">
				<div class="card-header">
					<h5>Hedef Gerçekleşme</h5>
				</div>
				<vuecharts :option="chartOption2" style="height: 500px" ref="chart2" />
			</div>
		</div>
	</div>
</template>

<script>


export default {

	data() {
		return {
			dates: null,
			
			sayi: 1,
			chartOption: {
				title: {
					text: ''
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['Studer', 'Lazer', 'Nomura', 'Komatech', 'Mazak']
				},
				toolbox: {
					feature: {
						saveAsImage: {}
					}
				},
				grid: {
					left: '3%',
					right: '4%',
					bottom: '3%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: 'Studer',
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: [120, 132, 101, 134, 90, 230, 210]
					},
					{
						name: 'Lazer',
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: [220, 182, 191, 234, 290, 330, 310]
					},
					{
						name: 'Nomura',
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: [150, 232, 201, 154, 190, 330, 410]
					},
					{
						name: 'Komatech',
						type: 'line',
						stack: 'Total',
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: [320, 332, 301, 334, 390, 330, 320]
					},
					{
						name: 'Mazak',
						type: 'line',
						stack: 'Total',
						label: {
							show: true,
							position: 'top'
						},
						areaStyle: {},
						emphasis: {
							focus: 'series'
						},
						data: [820, 932, 901, 934, 1290, 1330, 1320]
					}
				]
			},
			chartOption2: {
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999'
						}
					}
				},
				toolbox: {
					feature: {
						saveAsImage: { show: true }
					}
				},
				legend: {
					data: ['Evaporation', 'Precipitation', 'Hedef']
				},
				xAxis: [
					{
						type: 'category',
						data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
						axisPointer: {
							type: 'shadow'
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						name: 'Precipitation',
						min: 0,
						max: 250,
						interval: 50,
						axisLabel: {
							formatter: '{value} ml'
						}
					},
					{
						type: 'value',
						name: 'Hedef',
						min: 0,
						max: 25,
						interval: 5,
						axisLabel: {
							formatter: '{value} °C'
						}
					}
				],
				series: [
					{
						name: 'Evaporation',
						type: 'bar',
						tooltip: {
							valueFormatter: function (value) {
								return value + ' ml';
							}
						},
						data: [
							2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3
						]
					},
					{
						name: 'Precipitation',
						type: 'bar',
						tooltip: {
							valueFormatter: function (value) {
								return value + ' ml';
							}
						},
						data: [
							2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 182.2, 48.7, 18.8, 6.0, 2.3
						]
					},
					{
						name: 'Temperature',
						type: 'line',
						yAxisIndex: 1,
						tooltip: {
							valueFormatter: function (value) {
								return value + ' °C';
							}
						},
						data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2]
					}
				]
			},
		}
	},
	methods: {

	},
	computed: {
		profileData() {
			return this.$store.getters.getProfile;
		},
	},
}
</script>

<style lang="scss" scoped>

</style>
